import {mapGetters} from "vuex";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ApiService from "@/core/services/api.service";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import moment from "moment";

export default {
    props: {
        modal: {
            default: false
        },
        value: Boolean
    },
    name: "banner-instance",
    data() {
        return {
            valid: false,
            isSaving: false,
            bannerInstanceId: null,
            oobleeBannerInstance: {
                oobleeBannerDefinition: null,
                start: null,
                end: null,
                cityId: "",
                allCities: false,
                active: false
            },
            cities: [],
            bannerDefinitions: [],
            search: "",
            citiesIsLoading: false,
            bannersIsLoading: false,
            dateFromMenu: false,
            dateToMenu: false,
            requiredRule: [v => !!v || "Field is required"],
            dateRule: [
                v => !!v || 'Field is required',
                v => v.length > 10 || 'Field is required',
            ]
        };
    },
    created() {
        this.bannerInstanceId = this.$route.params.bannerInstanceId;
        let title =
            this.bannerInstanceId != null
                ? "Banner instance details"
                : "Insert new banner instance";
        this.$store.dispatch(SET_BREADCRUMB, [{title: title}]);
        if (this.bannerInstanceId != null) {
            ApiService.get(`api/banner-instance`, `${this.bannerInstanceId}`)
                .then(response => {
                    this.$log.debug("Banner instance: ", response.data);
                    this.oobleeBannerInstance = response.data;
                    this.oobleeBannerInstance.city != null ? this.oobleeBannerInstance.cityId = this.oobleeBannerInstance.city.id : this.oobleeBannerInstance.cityId = "";
                })
                .catch(error => {
                    this.$log.error("Error: ", error);
                    this.errored = true;
                })
                .finally(() => (this.loading = false));
        }
        this.searchCities();
        this.searchBannerDefinitions();
    },
    methods: {
        previousState() {
            this.$router.go(-1);
        },
        searchBannerDefinitions() {
            // Items have already been loaded
            if (this.bannerDefinitions.length > 0) return;

            // Items have already been requested
            if (this.bannersIsLoading) return;

            this.bannersIsLoading = true;
            let params = {};
            params["page"] = 1;
            params["size"] = 999;
            ApiService.query("api/banner-definition", {
                params
            })
                .then(res => {
                    this.bannerDefinitions = res.data.content;
                })
                .catch(error => {
                    this.$log.error("Error: ", error);
                    this.errored = true;
                })
                .finally(() => (this.bannersIsLoading = false));
        },
        searchCities() {
            // Items have already been loaded
            if (this.cities.length > 0) return;

            // Items have already been requested
            if (this.citiesIsLoading) return;

            this.citiesIsLoading = true;
            ApiService.query("api/cities")
                .then(res => {
                    this.cities = res.data;
                })
                .catch(error => {
                    this.$log.error("Error: ", error);
                    this.errored = true;
                })
                .finally(() => (this.citiesIsLoading = false));
        },
        isNonEmptyArrayOfStrings(value) {
            return (
                Array.isArray(value) &&
                value.length &&
                value.every(item => typeof item === "number")
            );
        },
        validateAndSave() {
            if (this.$refs.form.validate()) {
                this.saveBannerInstance(this.oobleeBannerInstance);
            }
        },
        saveBannerInstance(bannerInstance) {
            this.loading = true;
            if (bannerInstance.id != null) {
                this.$log.debug("Banner instance city for update: " + bannerInstance.cityId);
                ApiService.put(`api/banner-instance`, bannerInstance)
                    .then(response => {
                        this.$log.debug("Banner instance updated: " + response);
                        this.$emit("bannerInstanceSaved", "bannerInstanceSaved");
                    })
                    .catch(error => {
                        this.$log.error("Error: ", error);
                        this.errored = true;
                    })
                    .finally(() => {
                        this.loading = false;
                        this.previousState();
                    });
            } else {
                let bannerObject = {};
                bannerObject["id"] = bannerInstance.oobleeBannerDefinition;
                this.$log.debug("bannerObject:", bannerObject);
                bannerInstance.oobleeBannerDefinition = bannerObject;
                ApiService.post(`api/banner-instance`, bannerInstance)
                    .then(response => {
                        this.$log.debug("Banner instance created: " + response);
                        this.$emit("bannerInstanceSaved", "bannerInstanceSaved");
                    })
                    .catch(error => {
                        this.$log.error("Error: ", error);
                        this.errored = true;
                    })
                    .finally(() => {
                        this.loading = false;
                        this.previousState();
                    });
            }
        }
    },
    computed: {
        ...mapGetters(["currentUserPersonalInfo"]),
        ...mapGetters(["currentUser"]),
        show: {
            get() {
                return this.value;
            },
            set(value) {
                if (!value) {
                    this.clearFields();
                    this.$emit("input", value);
                }
            }
        },
        dateStartFormatted: {
            get() {
                if (this.oobleeBannerInstance.start == null || this.oobleeBannerInstance.start === "") return null;
                return moment
                    .utc(this.oobleeBannerInstance.start)
                    .local()
                    .format("yyyy-MM-DD HH:mm:ss");
                // return moment(this.oobleeBannerInstance.start, "YYY-MM-DDTHH:mm:ssZ").format("yyyy-MM-DD HH:mm:ss");
            },
            set(value) {
                this.oobleeBannerInstance.start = value;
            }
        },
        dateEndFormatted: {
            get() {
                if (this.oobleeBannerInstance.end == null || this.oobleeBannerInstance.end === "") return null;
                return moment
                    .utc(this.oobleeBannerInstance.end)
                    .local()
                    .format("yyyy-MM-DD HH:mm:ss");
            },
            set(value) {
                this.oobleeBannerInstance.end = value;
            }
        }
    }
};
